import axios, { AxiosInstance } from 'axios';

export type ErrorResponse = {
    errorCode: string;
    errorMessage?: string;
};

export type SuccessSentPhotoResponse = {
    registration: {
        id: string;
        validUntil: number;
    };
};

export type SuccessSentSelfieResponse = {
    registration: {
        id: string;
        publicKeyBase64: string;
        validUntil: number;
    };
};

export type CustomerMyInfoRequestParams = Required<AuthData> & {
    requestTransactionsSince: number;
    requestTransactionsUntil: number;
};

export type CustomerMyInfoRequestResponse = GetMyInfoResult;

export type AccountData = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    lastDigits: string;
    encryptedDataBase64: string;
};

class PayRestAPI {
    private restApiUrl = 'https://service.ntechlab.com/api';
    private token = 'oxvm9VLvtbYv0TMm9ULPR12WyAefoKgSZWGrm';
    private axiosInstance: AxiosInstance;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: this.restApiUrl,
            headers: { Authorization: `Bearer ${this.token}` },
        });
    }

    async getCustomerMyInfo(params: CustomerMyInfoRequestParams): Promise<CustomerMyInfoRequestResponse> {
        if (!this.axiosInstance) return Promise.reject('No api instance');

        const { data } = await this.axiosInstance.post<CustomerMyInfoRequestResponse>('/customers/my_info', params);

        return data;
    }

    async sendDocumentPhoto(picture: File): Promise<SuccessSentPhotoResponse> {
        if (!this.axiosInstance) return Promise.reject('No api instance');

        const formData = new FormData();
        formData.append('document', picture);

        const { data } = await this.axiosInstance.post<SuccessSentPhotoResponse>('/registrations', formData);

        return data;
    }

    async sendSelfie(registrationId: string, movie: File): Promise<SuccessSentSelfieResponse> {
        if (!this.axiosInstance) return Promise.reject('No api instance');

        const formData = new FormData();
        formData.append('file', movie);

        const { data } = await this.axiosInstance.post(`/registrations/${registrationId}/selfie`, formData);
        return data;
    }

    async sendData(registrationId: string, data: AccountData): Promise<SuccessSentPhotoResponse> {
        if (!this.axiosInstance) return Promise.reject('No api instance');

        const { data: responseData } = await this.axiosInstance.post(`/registrations/${registrationId}/data`, data);
        return responseData;
    }
}

export default new PayRestAPI();
