import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// import ru from './ru.json';
import en from './en.json';
// import es from './es.json';
// import pt from './pt.json';

i18n.use(initReactI18next).use(LanguageDetector).init({
    resources: {
        en: {
            translation: en,
        },
        // ru: {
        //     translation: ru,
        // },
        // es: {
        //     translation: es,
        // },
        // pt: {
        //     translation: pt,
        // },
    },
    fallbackLng: 'en',

    interpolation: {
        escapeValue: false,
    },
});
