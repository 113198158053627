import React, { useEffect, useState } from 'react';
import PhotoId from './PhotoId';
import PhotoFace from './PhotoFace';
import Registration from './Registration';
import PayData from './PayData';
import Success from './Success';
import useAppLoader from 'libs/useAppLoader';
import { useAppDispatch } from 'store/hooks';
import { clearDocumentData, clearSelfieData } from './slice';

enum Stage {
    USER_DATA,
    PHOTO_ID,
    PHOTO_FACE,
    PAY_DATA,
    SUCCESS,
}

const CreateAccount: React.FC = () => {
    const [stage, setStage] = useState<Stage>(Stage.USER_DATA);
    const dispatch = useAppDispatch();

    const [serverError, setServerError] = useState<boolean>(false);
    const [, setAppLoader] = useAppLoader();

    useEffect(() => {
        setAppLoader(false);
        setServerError(false);
    }, []);

    const toMainRegPage = () => setStage(Stage.USER_DATA);
    const toPhotoIdStage = () => {
        dispatch(clearDocumentData());
        setStage(Stage.PHOTO_ID);
    };
    const toPhotoFaceStage = () => {
        dispatch(clearSelfieData());
        setStage(Stage.PHOTO_FACE);
    };
    const toPayDataStage = () => setStage(Stage.PAY_DATA);
    const toSuccessPage = () => setStage(Stage.SUCCESS);

    if (serverError) return <div className="text-h3">Server connection error, try again later</div>;

    if (stage === Stage.USER_DATA) return <Registration complete={toPhotoIdStage} />;
    if (stage === Stage.PHOTO_ID) return <PhotoId backClick={toMainRegPage} complete={toPhotoFaceStage} />;
    if (stage === Stage.PHOTO_FACE) return <PhotoFace backClick={toPhotoIdStage} complete={toPayDataStage} />;
    if (stage === Stage.PAY_DATA) return <PayData backClick={toPhotoFaceStage} complete={toSuccessPage} />;
    if (stage === Stage.SUCCESS) return <Success continueClick={toMainRegPage} />;

    return <Registration complete={toPhotoIdStage} />;
};

export default CreateAccount;
