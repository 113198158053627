import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import PayRestAPI, { CustomerMyInfoRequestResponse } from 'libs/PayRestAPI';
import { AuthFormData } from 'features/AuthForm';

export interface AccountState {
    userData: null | AuthData;
    loadingTransactions: boolean;
    transactions: null | Transaction[];
    userInfo: null | {
        balance: string;
        lastCardNumberDigits: string;
        firstName: string;
        lastName: string;
    };
}

const initialState: AccountState = {
    userData: null,
    loadingTransactions: false,
    transactions: null,
    userInfo: null,
};

export const getTransactions = createAsyncThunk('account/getTransactions', async (params: void, thunkApi) => {
    try {
        const {
            account: { userData },
        } = thunkApi.getState() as RootState;

        if (!userData) return thunkApi.rejectWithValue({ errorCode: 'unauthorized', errorMessage: '' });

        const response = await PayRestAPI.getCustomerMyInfo({
            email: userData.email,
            password: userData.password,
            requestTransactionsSince: 1609459200,
            requestTransactionsUntil: 1893456000,
        });
        return Promise.resolve(response);
    } catch (e) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const response = e?.response?.data ?? { errorCode: 'server_error', errorMessage: '' };
        return thunkApi.rejectWithValue(response);
    }
});

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<AuthFormData>) => {
            state.userData = {
                ...action.payload,
            };
        },

        logOut: (state) => {
            state.userData = null;
            state.transactions = null;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getTransactions.pending, (state) => {
            state.loadingTransactions = true;
            state.transactions = null;
        });

        builder.addCase(getTransactions.fulfilled, (state, action) => {
            const { transactions, balance, firstName, lastDigits, lastName } = action.payload as CustomerMyInfoRequestResponse;

            state.loadingTransactions = false;
            state.transactions = transactions;

            state.userInfo = {
                firstName,
                lastName,
                balance,
                lastCardNumberDigits: lastDigits,
            };
        });

        builder.addCase(getTransactions.rejected, (state) => {
            state.loadingTransactions = false;
            state.transactions = null;
            state.userInfo = null;
        });
    },
});

export const { login, logOut } = accountSlice.actions;
export const selectUserData = (state: RootState): AccountState['userData'] => state.account.userData;
export const selectTransactions = (state: RootState): AccountState['transactions'] => state.account.transactions;
export const selectUserInfo = (state: RootState): AccountState['userInfo'] => state.account.userInfo;
export const selectLoadingTransactions = (state: RootState): AccountState['loadingTransactions'] =>
    state.account.loadingTransactions;

export default accountSlice.reducer;
