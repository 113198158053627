import React, { forwardRef, useState } from 'react';
import Input, { Props as InputProps } from 'components/Input';
import Button from 'components/Button';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import cn from 'classnames';
import { refSetter } from 'libs/refSetter';
import css from './styles.module.scss';

export interface Props extends InputProps {
    errors?: string[];
}

const InputField = forwardRef<HTMLInputElement, Props>(({ className, errors, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const hasErrors = Boolean(errors?.length);
    const isPasswordField = rest.type === 'password';

    const toggleShowPassword = () => setShowPassword((val) => !val);

    return (
        <div
            className={cn(css.field, className, { [css.hasErrors]: hasErrors, [css.hasButton]: !hasErrors && isPasswordField })}
        >
            <div className={css.inputWrap}>
                <Input
                    className={css.input}
                    {...rest}
                    {...(isPasswordField ? { type: showPassword ? 'text' : 'password' } : {})}
                    ref={refSetter(ref, inputRef)}
                />

                {hasErrors && (
                    <div className={cn(css.icon, { [css.hasErrors]: hasErrors })}>
                        <CrossIcon width={24} height={24} />
                    </div>
                )}

                {!hasErrors && isPasswordField && (
                    <Button
                        displayAsRound
                        onClick={toggleShowPassword}
                        className={cn(css.passButton, { [css.show]: showPassword })}
                        dimension="xs"
                        appearance="white-transparent"
                    >
                        <EyeIcon />
                    </Button>
                )}
            </div>

            {hasErrors && <div className={css.errors}>{errors?.join(', ')}</div>}
        </div>
    );
});

export default InputField;
