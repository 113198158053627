import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIMask } from 'react-imask';
import Page from 'components/Page';
import Header from 'components/Header';
import AdaptiveIcons from 'components/AdaptiveIcons';
import useAppLoader from 'libs/useAppLoader';
import { Controller, useForm, FieldErrors } from 'react-hook-form';
import InputField from 'components/form/InputField';
import Button from 'components/Button';
import * as yup from 'yup';
import { ReactComponent as SignUpIcon } from 'assets/icons/signup.svg';
import cn from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    selectAccountData,
    selectPayDataErrors,
    selectPublicKeyBase64,
    selectSendPayDataSuccess,
    sendAccountDataAsync,
} from '../slice';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Unicrypto from 'unicrypto';
import css from './styles.module.scss';

// Unicrypto.setup({
//     libraryPath: location.origin,
// });

export interface Props {
    backClick: () => void;
    complete: () => void;
}

type CardFormData = {
    number: string;
    valid: string;
    name: string;
    cvc: string;
};

const schema = yup
    .object({
        number: yup.string().required().min(19),
        valid: yup.string().required().min(5),
        name: yup.string().required(),
        cvc: yup.string().required().min(3),
    })
    .required();

const PayData: React.FC<Props> = ({ backClick, complete }) => {
    const { t } = useTranslation();
    const [loading, setAppLoader] = useAppLoader();
    const dispatch = useAppDispatch();

    const sendPayDataSuccess = useAppSelector(selectSendPayDataSuccess);
    const accountData = useAppSelector(selectAccountData);
    const publicKeyBase64 = useAppSelector(selectPublicKeyBase64);
    const payDataErrors = useAppSelector(selectPayDataErrors);

    const { ref: cardNumberRef } = useIMask({ mask: '0000 0000 0000 000000' });
    const { ref: cvcRef } = useIMask({ mask: '000' });
    const { ref: validRef } = useIMask({ mask: '00/00' });

    useEffect(() => {
        if (!sendPayDataSuccess && payDataErrors)
            Object.keys(payDataErrors).forEach((fieldName) =>
                setError(fieldName as keyof CardFormData, {
                    type: 'server-error',
                    message: payDataErrors?.[fieldName as keyof CardFormData],
                }),
            );

        if (!payDataErrors && sendPayDataSuccess) complete();
    }, [sendPayDataSuccess, payDataErrors]);

    const onSubmit = async (formData: CardFormData) => {
        if (!accountData || !publicKeyBase64) {
            return;
        }

        setAppLoader(true);

        const data = {
            card: formData,
        };

        const publicKey = await Unicrypto.PublicKey.unpack(Unicrypto.decode64(publicKeyBase64));
        const encrypted = await publicKey.encrypt(Unicrypto.Boss.dump(data));

        dispatch(
            sendAccountDataAsync({
                data: {
                    firstName: accountData.name,
                    lastName: accountData.surname,
                    email: accountData.email,
                    password: accountData.password,
                    lastDigits: formData.number.slice(-4),
                    encryptedDataBase64: Unicrypto.encode64(encrypted),
                },
            }),
        ).finally(() => {
            setAppLoader(false);
        });
    };

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<CardFormData>({
        // defaultValues: {
        //     number: '1234 1234 1234 1234',
        //     valid: '12/23',
        //     name: 'asdasdasd asd',
        //     cvc: '123',
        // },
        resolver: yupResolver(schema),
    });

    const getFieldError = (errors: FieldErrors<CardFormData>, fieldName: keyof CardFormData): string[] | undefined => {
        if (!errors[fieldName]) return;

        if (errors[fieldName]?.type === 'server-error') return [errors[fieldName]?.message ?? ''];

        return [t(`errors.${errors[fieldName]?.type}`)];
    };
    return (
        <Page>
            <Header backClick={backClick} />

            <Page.Content className={css.content}>
                <Page.Icon>
                    <AdaptiveIcons name="pay-card" />
                </Page.Icon>

                <h3 className={css.title}>{t('addCard')}</h3>

                <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
                    <fieldset className={css.fieldGroup}>
                        <Controller
                            name="number"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    {...field}
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    /*
                                    // @ts-ignore */
                                    ref={cardNumberRef}
                                    autoComplete="no"
                                    type="tel"
                                    className={cn(css.field, css.numberField)}
                                    placeholder={t('cardNumber')}
                                    disabled={loading}
                                    errors={getFieldError(errors, 'number')}
                                />
                            )}
                        />

                        <Controller
                            name="valid"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    {...field}
                                    autoComplete="no"
                                    type="tel"
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    /*
                                    // @ts-ignore */
                                    ref={validRef}
                                    className={cn(css.field, css.validField)}
                                    placeholder={t('trueValid')}
                                    errors={getFieldError(errors, 'valid')}
                                    disabled={loading}
                                />
                            )}
                        />
                    </fieldset>

                    <fieldset className={css.fieldGroup}>
                        <Controller
                            name="name"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    {...field}
                                    autoComplete="no"
                                    type="text"
                                    className={cn(css.field, css.cardHolderField)}
                                    placeholder={t('cardholderName')}
                                    errors={getFieldError(errors, 'name')}
                                    disabled={loading}
                                />
                            )}
                        />

                        <Controller
                            name="cvc"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    {...field}
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    /*
                                    // @ts-ignore */
                                    ref={cvcRef}
                                    type="tel"
                                    className={cn(css.field, css.cvcField)}
                                    placeholder={t('cvc')}
                                    errors={getFieldError(errors, 'cvc')}
                                    disabled={loading}
                                />
                            )}
                        />
                    </fieldset>

                    <Button
                        type="submit"
                        className={css.button}
                        icon={<SignUpIcon />}
                        appearance="white-fill"
                        disabled={loading}
                    >
                        {t('continue')}
                    </Button>
                </form>
            </Page.Content>
        </Page>
    );
};

export default PayData;
