import React, { useEffect } from 'react';
import Page from 'components/Page';
import { Link, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import * as routes from 'routes';
import Header from 'components/Header';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    getTransactions,
    logOut,
    selectLoadingTransactions,
    selectTransactions,
    selectUserData,
    selectUserInfo,
} from 'pages/Account/slice';
import { stringFormatMoney } from 'libs';
import { ReactComponent as MarkIcon } from 'assets/icons/mark.svg';
import css from './styles.module.scss';

const Transactions: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { push } = useHistory();
    const useData = useAppSelector(selectUserData);
    const transactions = useAppSelector(selectTransactions);
    const loading = useAppSelector(selectLoadingTransactions);
    const userInfo = useAppSelector(selectUserInfo);

    useEffect(() => {
        if (!useData) {
            push(routes.login());
            return;
        }

        dispatch(getTransactions());
    }, []);

    const logOutClick = () => {
        dispatch(logOut());
    };

    return (
        <Page type="success">
            <Header>
                <Link onClick={logOutClick} className={css.headerLink} to={routes.login()}>
                    {t('logOut')}
                </Link>
            </Header>

            <Page.Content className={css.content}>
                <div className={css.top}>
                    <div className={css.name}>
                        {userInfo?.firstName} <br />
                        {userInfo?.lastName}
                    </div>

                    <div className={css.card}>
                        <div className={css.balance}>$ {userInfo?.balance && stringFormatMoney(userInfo?.balance)}</div>
                        <div className={css.number}>**** **** **** {userInfo?.lastCardNumberDigits}</div>
                    </div>
                </div>

                <div className={css.tableHead}>{t('operationsFeed')}</div>

                <div className={css.table}>
                    {transactions &&
                        transactions.map((t, index) => (
                            <div className={css.row} key={index}>
                                <MarkIcon className={css.rowIcon} />
                                <div className={css.sum}>–${stringFormatMoney(t.amount)}</div>

                                <div className={css.other}>
                                    <div className={css.terminal}>{t.id}</div>
                                    <div className={css.dateTime}>
                                        {format(new Date(t.createdAt * 1000), 'yyyy-MM-dd hh:mm')}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </Page.Content>
        </Page>
    );
};

export default Transactions;
