import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'components/Page';
import Header from 'components/Header';
import cn from 'classnames';
import Button from 'components/Button';
import Selfie from 'components/Selfie';
import AdaptiveIcons from 'components/AdaptiveIcons';
import selfieImageSrc from './selfie.svg';
import { ReactComponent as BulletArrowIcon } from 'assets/icons/bullet-arrow.svg';
import { ReactComponent as RepeatIcon } from 'assets/icons/repeat.svg';
import { ReactComponent as MarkIcon } from 'assets/icons/mark.svg';
import css from './styles.module.scss';
import useAppLoader from 'libs/useAppLoader';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectPublicKeyBase64, selectSelfieErrorCode, selectSelfieErrorMessage, sendSelfieAsync } from '../slice';
import { SERVER_ERROR_TYPE, getServerErrorMessage, ServerErrorMessage } from 'libs/serverErrorMessages';

export interface Props {
    backClick: () => void;
    complete: () => void;
}

type Stage = 'info' | 'photo' | 'error';

const PhotoFace: React.FC<Props> = ({ complete, backClick }) => {
    const [stage, setStage] = useState<Stage>();
    const [errorType, setErrorType] = useState<SERVER_ERROR_TYPE>(SERVER_ERROR_TYPE.UNKNOWN_ERROR);

    const toPhotoStage = () => setStage('photo');
    const toInfoStage = () => setStage('info');

    const toErrorStage = (errorType: SERVER_ERROR_TYPE) => {
        setErrorType(errorType);
        setStage('error');
    };

    const [, setAppLoader] = useAppLoader();
    const dispatch = useAppDispatch();
    const selfieErrorCode = useAppSelector(selectSelfieErrorCode);
    const selfieErrorMessage = useAppSelector(selectSelfieErrorMessage);
    const publicKeyBase64 = useAppSelector(selectPublicKeyBase64);

    useEffect(() => {
        if (!publicKeyBase64 && selfieErrorCode) {
            let foundServerErrorType;

            if (selfieErrorCode.toLowerCase() === 'bad_video' && selfieErrorMessage)
                foundServerErrorType = SERVER_ERROR_TYPE[selfieErrorMessage as keyof typeof SERVER_ERROR_TYPE];

            if (foundServerErrorType !== undefined) {
                toErrorStage(foundServerErrorType);
                return;
            }

            toErrorStage(SERVER_ERROR_TYPE.UNKNOWN_ERROR);
        }

        if (!selfieErrorCode && publicKeyBase64) complete();
    }, [selfieErrorCode, publicKeyBase64]);

    const movieSuccess = async (blob: Blob) => {
        setAppLoader(true);
        const file = new File([blob], 'document');

        dispatch(sendSelfieAsync({ movie: file })).finally(() => {
            setAppLoader(false);
        });
    };

    if (stage === 'photo') return <Photo backClick={toInfoStage} movieSuccess={movieSuccess} />;

    if (stage === 'error')
        return (
            <Error backClick={toInfoStage} repeatAgainClick={toPhotoStage} errorMessage={getServerErrorMessage(errorType)} />
        );

    return <Info backClick={backClick} startClick={toPhotoStage} />;
};

type InfoProps = {
    backClick: () => void;
    startClick: () => void;
};

const Info: React.FC<InfoProps> = ({ backClick, startClick }) => {
    const { t } = useTranslation();

    return (
        <Page>
            <Header backClick={backClick} />

            <Page.Content className={css.infoContent}>
                <h4 className={css.infoTitle}>{t('addSelfie')}</h4>
                <div className={cn(css.infoText, 'text-normal')}>{t('createSelfie')}</div>

                <Page.DesktopRightSideContent className={css.desktopSidebar}>
                    <img className={css.infoImage} src={selfieImageSrc} alt={t('createSelfie')} width={398} height={421} />
                </Page.DesktopRightSideContent>

                <Button className={css.startButton} appearance="violet-fill" onClick={startClick}>
                    {t('start')}
                </Button>
            </Page.Content>
        </Page>
    );
};

type ErrorProps = {
    backClick: () => void;
    repeatAgainClick: () => void;
    errorMessage: ServerErrorMessage;
};

const Error: React.FC<ErrorProps> = ({ backClick, repeatAgainClick, errorMessage: { title, points } }) => {
    const { t } = useTranslation();

    return (
        <Page type="error">
            <Header backClick={backClick} title={t('error')} />

            <Page.Content className={css.errorContent}>
                <Page.Icon>
                    <AdaptiveIcons name={'cross'} />
                </Page.Icon>

                <Page.Title>{title}</Page.Title>

                <Page.List title={t('itCanBe')}>
                    {points.map((point, index) => (
                        <Page.ListItem bulletIcon={<BulletArrowIcon />} key={index}>
                            {point}
                        </Page.ListItem>
                    ))}
                </Page.List>

                <Page.Footer>
                    <Button
                        className={css.errorButton}
                        icon={<RepeatIcon />}
                        appearance="white-fill"
                        onClick={repeatAgainClick}
                    >
                        {t('repeatAgain')}
                    </Button>
                </Page.Footer>
            </Page.Content>
        </Page>
    );
};

type PhotoProps = {
    backClick: () => void;
    movieSuccess: (file: Blob) => void;
};

const Photo: React.FC<PhotoProps> = ({ backClick, movieSuccess }) => {
    const { t } = useTranslation();
    const photoOptions = [t('takePhotoYourFace_option_1'), t('takePhotoYourFace_option_2')];

    const takeMovie = async (movie: Blob | null) => {
        if (movie) {
            movieSuccess(movie);
        }
    };

    return (
        <Selfie back={backClick} complete={takeMovie} title={null}>
            <Page.List className={css.list}>
                {photoOptions.map((i, index) => (
                    <Page.ListItem bulletIcon={<MarkIcon width={29} height={29} />} key={index}>
                        {i}
                    </Page.ListItem>
                ))}
            </Page.List>
        </Selfie>
    );
};

export default PhotoFace;
