import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import PayRestAPI, { SuccessSentPhotoResponse, ErrorResponse, SuccessSentSelfieResponse, AccountData } from 'libs/PayRestAPI';
import { RegFormData } from 'features/RegistrationForm';

export interface CreateAccountState {
    accountData?: RegFormData;
    loading: boolean;
    registrationId: null | string;
    validUntil: null | number;
    documentError: null | string;
    selfieErrorCode: null | string;
    selfieErrorMessage: null | string;
    publicKeyBase64: null | string;
    sendPayDataSuccess: null | boolean;
    payDataErrors: null | { [key: string]: string };
}

const initialState: CreateAccountState = {
    loading: false,
    // registrationId: '25',
    registrationId: null,
    validUntil: null,
    documentError: null,
    selfieErrorCode: null,
    selfieErrorMessage: null,
    publicKeyBase64: null,
    // publicKeyBase64:
    //     'HggcAQABxAAByd0VmfkmlnQV7F2IzPmEPpL/v3pT8PkDW+eszQi/ED5nN3o66rdfhWEhm5Md8AORTDRtEkl/DpJljj8VhM3DElRhDyyZzzOazbf5lZm/dOWw35txdEPQFtlifmKyQQvLkMHY99wDWgC3SFf8xUffToneCXL727VksJZfhSov/krh3YwE2UxgeHkH4SMfI6qmbYTubgUtKbcg2OtRovFAlhqnisI8u96JLozaM/FAGX8iKJmahRAIWJiIQeTD7EFYPCx9xdbPsZzJFOGT7WN7mOP2s21TxFeoEJLV6/5uCJmfza2eR7BEo1i2yVERj2U62vhlIIhzRuAoYH4kSqopJw==',
    sendPayDataSuccess: null,
    payDataErrors: null,

    // accountData: {
    //     name: 'Oleg',
    //     surname: 'Vavilov',
    //     email: 'oleg@oleg.ru',
    //     password: 'oleg',
    // },
};

export const sendDocumentAsync = createAsyncThunk('accountCreate/sendDocument', async (params: { picture: File }, thunkApi) => {
    try {
        const response = await PayRestAPI.sendDocumentPhoto(params.picture);
        return Promise.resolve(response);
    } catch (e) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const response = e?.response?.data ?? { errorCode: 'bad_photo', errorMessage: '' };
        return thunkApi.rejectWithValue(response);
    }
});

export const sendSelfieAsync = createAsyncThunk('accountCreate/sendSelfie', async (params: { movie: File }, thunkApi) => {
    const {
        accountCreate: { registrationId },
    } = thunkApi.getState() as RootState;

    if (!registrationId) return thunkApi.rejectWithValue({ errorCode: 'no_registration_id', errorMessage: '' });

    try {
        const response = await PayRestAPI.sendSelfie(registrationId, params.movie);
        return Promise.resolve(response);
    } catch (e) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const response = e?.response?.data ?? { errorCode: 'bad_video', errorMessage: '' };
        return thunkApi.rejectWithValue(response);
    }
});

export const sendAccountDataAsync = createAsyncThunk(
    'accountCreate/sendAccountData',
    async (params: { data: AccountData }, thunkApi) => {
        const {
            accountCreate: { registrationId },
        } = thunkApi.getState() as RootState;

        if (!registrationId) return thunkApi.rejectWithValue({ errorCode: 'no_registration_id', errorMessage: '' });

        try {
            const response = await PayRestAPI.sendData(registrationId, params.data);
            return Promise.resolve(response);
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const response = e?.response?.data ?? { errorCode: 'bad_request', errorMessage: '' };
            return thunkApi.rejectWithValue(response);
        }
    },
);

export const accountCreateSlice = createSlice({
    name: 'accountCreate',
    initialState,
    reducers: {
        startOnboarding: (state, action: PayloadAction<RegFormData>) => {
            state.accountData = action.payload;
            state.sendPayDataSuccess = null;
            state.payDataErrors = null;
        },

        clearDocumentData: (state) => {
            state.registrationId = null;
            state.validUntil = null;
            state.documentError = null;
        },

        clearSelfieData: (state) => {
            state.selfieErrorCode = null;
            state.selfieErrorMessage = null;
            state.publicKeyBase64 = null;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(sendDocumentAsync.pending, (state) => {
            state.loading = true;
            state.registrationId = null;
            state.validUntil = null;
            state.documentError = null;
        });

        builder.addCase(sendDocumentAsync.fulfilled, (state, action) => {
            state.loading = false;

            const {
                registration: { id, validUntil },
            } = action.payload as SuccessSentPhotoResponse;

            state.registrationId = id ?? null;
            state.validUntil = validUntil ?? null;
        });

        builder.addCase(sendDocumentAsync.rejected, (state, action) => {
            state.loading = false;
            const { errorCode } = action.payload as ErrorResponse;
            state.documentError = errorCode;
        });

        builder.addCase(sendSelfieAsync.pending, (state) => {
            state.loading = true;
            state.publicKeyBase64 = null;
            state.selfieErrorCode = null;
            state.selfieErrorMessage = null;
        });

        builder.addCase(sendSelfieAsync.fulfilled, (state, action) => {
            state.loading = false;

            const {
                registration: { publicKeyBase64 },
            } = action.payload as SuccessSentSelfieResponse;
            state.publicKeyBase64 = publicKeyBase64;
        });

        builder.addCase(sendSelfieAsync.rejected, (state, action) => {
            state.loading = false;
            const { errorCode, errorMessage } = action.payload as ErrorResponse;
            state.selfieErrorCode = errorCode;
            state.selfieErrorMessage = errorMessage ?? null;
        });

        builder.addCase(sendAccountDataAsync.pending, (state) => {
            state.loading = true;
            state.sendPayDataSuccess = null;
            state.payDataErrors = null;
        });

        builder.addCase(sendAccountDataAsync.fulfilled, (state) => {
            state.loading = false;
            state.sendPayDataSuccess = true;
            state.payDataErrors = null;
            state.registrationId = null;
            state.validUntil = null;
            state.documentError = null;
            state.selfieErrorCode = null;
            state.selfieErrorMessage = null;
            state.publicKeyBase64 = null;
            state.accountData = undefined;
        });

        builder.addCase(sendAccountDataAsync.rejected, (state, action) => {
            state.loading = false;
            state.loading = false;
            const { errorCode, errorMessage } = action.payload as ErrorResponse;
            state.sendPayDataSuccess = false;
            state.payDataErrors = { [errorCode]: errorMessage ?? '' };
        });
    },
});

export const { startOnboarding, clearDocumentData, clearSelfieData } = accountCreateSlice.actions;
export const selectAccountData = (state: RootState): RegFormData | undefined => state.accountCreate.accountData;
export const selectRegistrationId = (state: RootState): string | null => state.accountCreate.registrationId;
export const selectValidUntil = (state: RootState): number | null => state.accountCreate.validUntil;
export const selectDocumentError = (state: RootState): string | null => state.accountCreate.documentError;
export const selectSelfieErrorCode = (state: RootState): string | null => state.accountCreate.selfieErrorCode;
export const selectSelfieErrorMessage = (state: RootState): string | null => state.accountCreate.selfieErrorMessage;
export const selectPublicKeyBase64 = (state: RootState): string | null => state.accountCreate.publicKeyBase64;
export const selectPayDataErrors = (state: RootState): { [key: string]: string } | null => state.accountCreate.payDataErrors;
export const selectSendPayDataSuccess = (state: RootState): boolean | null => state.accountCreate.sendPayDataSuccess;

export default accountCreateSlice.reducer;
