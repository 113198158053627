import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'components/Page';
import Header from 'components/Header';
import RegistrationForm, { RegFormData } from 'features/RegistrationForm';
import AdaptiveIcons from 'components/AdaptiveIcons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAccountData, startOnboarding } from 'pages/Account/Create/slice';
import { Link } from 'react-router-dom';
import * as routes from 'routes';
import css from './styles.module.scss';

export interface Props {
    complete: () => void;
}

const Registration: React.FC<Props> = ({ complete }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const accountData = useAppSelector(selectAccountData);

    const onSubmit = async (
        formData: RegFormData,
    ): Promise<{ success: boolean; errors?: { [key in keyof RegFormData]?: string } }> => {
        dispatch(startOnboarding(formData));
        complete();
        return Promise.resolve({ success: true });
    };

    return (
        <Page type="success">
            <Header>
                <Link className={css.headerLink} to={routes.login()}>
                    {t('logIn')}
                </Link>
            </Header>

            <Page.Content className={css.content}>
                <Page.Icon>
                    <AdaptiveIcons name="mark" />
                </Page.Icon>

                <RegistrationForm className={css.form} onSubmit={onSubmit} defaultValue={accountData} />
            </Page.Content>
        </Page>
    );
};

export default Registration;
