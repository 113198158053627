import React, { createRef, useEffect, useRef, useState } from 'react';
import Page from 'components/Page';
import Header from 'components/Header';
import cn from 'classnames';
import Button from 'components/Button';
import AdaptiveIcons from 'components/AdaptiveIcons';
import profileImageSrc from './profile.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MarkIcon } from 'assets/icons/mark.svg';
import { ReactComponent as TakePhotoIcon } from 'assets/icons/take-photo.svg';
import { ReactComponent as BulletArrowIcon } from 'assets/icons/bullet-arrow.svg';
import { ReactComponent as RepeatIcon } from 'assets/icons/repeat.svg';
import Camera from 'libs/camera/Camera';
import css from './styles.module.scss';
import useAppLoader from 'libs/useAppLoader';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectDocumentError, selectRegistrationId, sendDocumentAsync } from '../slice';
import { base64ToFile } from '../../../../libs';
import document from '../../../../mocks/document';

export interface Props {
    backClick: () => void;
    complete: () => void;
}

const PhotoId: React.FC<Props> = ({ complete, backClick }) => {
    const [stage, setStage] = useState<'info' | 'photo' | 'error' | 'exists-error'>('info');
    const [, setAppLoader] = useAppLoader();
    const dispatch = useAppDispatch();
    const documentError = useAppSelector(selectDocumentError);
    const registrationId = useAppSelector(selectRegistrationId);

    const toPhotoStage = () => setStage('photo');
    const toInfoStage = () => setStage('info');
    const toErrorStage = () => setStage('error');
    const toExistErrorStage = () => setStage('exists-error');

    useEffect(() => {
        if (!registrationId && documentError) documentError === 'user_exist' ? toExistErrorStage() : toErrorStage();

        if (!documentError && registrationId) complete();
    }, [documentError, registrationId]);

    const photoSuccess = async (blob: Blob) => {
        setAppLoader(true);
        const file = new File([blob], 'document');

        // TODO удалить после имплементации webm на сервер
        // const mockFile = base64ToFile(document, 'image/jpeg');

        dispatch(sendDocumentAsync({ picture: file })).finally(() => {
            setAppLoader(false);
        });
    };

    if (stage === 'photo') return <Photo backClick={toInfoStage} photoSuccess={photoSuccess} />;
    if (stage === 'exists-error') return <ExistsError backClick={toPhotoStage} repeatAgainClick={toPhotoStage} />;
    if (stage === 'error') return <Error backClick={toPhotoStage} repeatAgainClick={toPhotoStage} />;

    return <Info backClick={backClick} startClick={toPhotoStage} />;
};

type InfoProps = {
    backClick: () => void;
    startClick: () => void;
};

const Info: React.FC<InfoProps> = ({ backClick, startClick }) => {
    const { t } = useTranslation();

    return (
        <Page>
            <Header backClick={backClick} />

            <Page.Content className={css.infoContent}>
                <h4 className={css.infoTitle}>{t('photoIdTitle')}</h4>

                <div className={cn(css.infoText, 'text-normal')}>{t('photoIdDescription')}</div>

                <Page.DesktopRightSideContent className={css.desktopSidebar}>
                    <img className={css.image} src={profileImageSrc} width={470} height={392} alt="Profile image" />
                </Page.DesktopRightSideContent>

                <Button className={css.startButton} appearance="violet-fill" onClick={startClick}>
                    {t('start')}
                </Button>
            </Page.Content>
        </Page>
    );
};

type ErrorProps = {
    backClick: () => void;
    repeatAgainClick: () => void;
};

const Error: React.FC<ErrorProps> = ({ backClick, repeatAgainClick }) => {
    const { t } = useTranslation();

    const points = [t('takePhotoYourId_error_1'), t('takePhotoYourId_error_2')];

    return (
        <Page type="error">
            <Header backClick={backClick} title={t('error')} />

            <Page.Content>
                <Page.Icon>
                    <AdaptiveIcons name="cross" />
                </Page.Icon>

                <Page.Title>{t('badPhoto')}</Page.Title>

                <Page.List title={t('itCanBe')}>
                    {points.map((point, index) => (
                        <Page.ListItem bulletIcon={<BulletArrowIcon />} key={index}>
                            {point}
                        </Page.ListItem>
                    ))}
                </Page.List>

                <Page.Footer>
                    <Button icon={<RepeatIcon />} appearance="white-fill" onClick={repeatAgainClick}>
                        {t('repeatAgain')}
                    </Button>
                </Page.Footer>
            </Page.Content>
        </Page>
    );
};

type ExistsErrorProps = {
    backClick: () => void;
    repeatAgainClick: () => void;
};

const ExistsError: React.FC<ExistsErrorProps> = ({ backClick, repeatAgainClick }) => {
    const { t } = useTranslation();

    return (
        <Page type="error">
            <Header backClick={backClick} title={t('error')} />

            <Page.Content>
                <Page.Icon>
                    <AdaptiveIcons name="cross" />
                </Page.Icon>

                <Page.Title>{t('userExists')}</Page.Title>

                <Page.Footer>
                    <Button icon={<RepeatIcon />} appearance="white-fill" onClick={repeatAgainClick}>
                        {t('repeatAgain')}
                    </Button>
                </Page.Footer>
            </Page.Content>
        </Page>
    );
};

type PhotoProps = {
    backClick: () => void;
    photoSuccess: (file: Blob) => void;
};

const Photo: React.FC<PhotoProps> = ({ backClick, photoSuccess }) => {
    const { t } = useTranslation();
    const cameraContainerRef = createRef<HTMLDivElement>();
    const cameraInstance = useRef<Camera>();

    useEffect(() => {
        if (cameraContainerRef.current && !cameraInstance.current) {
            cameraInstance.current = new Camera(cameraContainerRef.current, 'environment');
        }

        return () => {
            cameraInstance.current?.destroy();
        };
    }, []);

    const takePhoto = (event: React.MouseEvent<HTMLButtonElement>) => {
        const button = event.currentTarget;

        button.disabled = true;

        cameraInstance.current
            ?.takePhoto()
            ?.then(async (blob) => {
                if (blob) {
                    photoSuccess(blob);
                }
            })
            .catch((e) => console.log(e))
            .finally(() => {
                if (button) button.disabled = false;
            });
    };

    const options = [t('takePhotoYourId_option_1')];

    return (
        <Page>
            <Header className={css.header} backClick={backClick} />

            <Page.Content className={css.content}>
                <Page.DesktopRightSideContent className={css.photoDesktopSidebar}>
                    <div ref={cameraContainerRef} className={css.camera} />
                    <div className={css.frame} />
                </Page.DesktopRightSideContent>

                <ul className={css.list}>
                    {options.map((i, index) => (
                        <li className={css.item} key={index}>
                            <MarkIcon className={css.itemIcon} width={29} height={29} />
                            <div className={css.itemTitle}>{i}</div>
                        </li>
                    ))}
                </ul>

                <Button className={css.button} appearance="violet-fill" icon={<TakePhotoIcon />} onClick={takePhoto}>
                    {t('takePhoto')}
                </Button>
            </Page.Content>
        </Page>
    );
};

export default PhotoId;
