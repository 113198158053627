import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import CreateAccount from 'pages/Account/Create';
import Login from 'pages/Account/Login';
import Transactions from 'pages/Account/Transactions';

import useWindowInnerHeight from 'libs/useWindowInnerHeight';
import * as routes from 'routes';

import css from './styles.module.scss';
import cn from 'classnames';

export const AppLoaderContext = React.createContext({
    showLoader: false,
    setLoader: (value: boolean) => console.log(value),
});

const App: React.FC = () => {
    const innerHeight = useWindowInnerHeight();
    const { push, location } = useHistory();
    const [showLoader, setShowLoader] = useState<boolean>(false);

    const setLoader = (value: boolean) => setShowLoader(value);

    useEffect(() => {
        if (location.pathname !== routes.main()) push(routes.main());
    }, []);

    return (
        <AppLoaderContext.Provider value={{ showLoader, setLoader }}>
            <div className={css.app} style={{ minHeight: `${innerHeight}px` }}>
                <div className={cn(css.loaderContainer, { [css.show]: showLoader })}>
                    <div className={css.loaderWrapper}>
                        <div className={css.loader} />
                    </div>
                </div>

                <Switch>
                    <Route exact path={routes.login()} component={Login} />
                    <Route exact path={routes.lk()} component={Transactions} />
                    <Route exact path={routes.registration()} component={CreateAccount} />
                </Switch>
            </div>
        </AppLoaderContext.Provider>
    );
};

export default App;
