import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputField from 'components/form/InputField';
import Button from 'components/Button';
import { ReactComponent as SignUpIcon } from 'assets/icons/signup.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

const schema = yup
    .object({
        name: yup.string().required(),
        surname: yup.string().required(),
        email: yup.string().email().required(),
        password: yup.string().required(),
    })
    .required();

export type RegFormData = {
    name: string;
    surname: string;
    email: string;
    password: string;
};

export interface Props extends Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onSubmit' | 'defaultValue'> {
    defaultValue?: RegFormData;
    submitTitle?: string;
    submitIcon?: React.ReactNode;
    disabled?: boolean;
    onSubmit: (data: RegFormData) => Promise<{ success: boolean; errors?: { [key in keyof RegFormData]?: string } }>;
}

const RegistrationForm: React.FC<Props> = ({
    onSubmit,
    defaultValue,
    submitTitle,
    submitIcon = <SignUpIcon />,
    disabled,
    ...rest
}) => {
    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<RegFormData>({
        resolver: yupResolver(schema),
        defaultValues: defaultValue,
    });

    const submitAction = async (data: RegFormData) => {
        const result = await onSubmit(data);

        if (!result.success) {
            Object.keys(result?.errors ?? {}).forEach((fieldName) =>
                setError(fieldName as keyof RegFormData, {
                    type: result?.errors?.[fieldName as keyof RegFormData],
                    message: 'error',
                }),
            );
        }
    };

    return (
        <form className={css.form} onSubmit={handleSubmit(submitAction)} {...rest}>
            <Controller
                name="name"
                defaultValue=""
                control={control}
                render={({ field }) => (
                    <InputField
                        {...field}
                        autoComplete="no"
                        type="text"
                        className={css.field}
                        placeholder={t('Name')}
                        errors={errors.name ? [t(`errors.${errors.name.type}`)] : undefined}
                        disabled={disabled}
                    />
                )}
            />

            <Controller
                name="surname"
                defaultValue=""
                control={control}
                render={({ field }) => (
                    <InputField
                        {...field}
                        autoComplete="no"
                        type="text"
                        className={css.field}
                        placeholder={t('Surname')}
                        errors={errors.surname ? [t(`errors.${errors.surname.type}`)] : undefined}
                        disabled={disabled}
                    />
                )}
            />

            <Controller
                name="email"
                defaultValue=""
                control={control}
                render={({ field }) => (
                    <InputField
                        {...field}
                        autoComplete="no"
                        type="text"
                        className={css.field}
                        placeholder={t('Email')}
                        errors={errors.email ? [t(`errors.${errors.email.type}`)] : undefined}
                        disabled={disabled}
                    />
                )}
            />

            <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <InputField
                        {...field}
                        autoComplete="no"
                        className={css.field}
                        type="password"
                        errors={errors.password ? [t(`errors.${errors.password.type}`)] : undefined}
                        placeholder={t('password')}
                        disabled={disabled}
                    />
                )}
            />

            <Button type="submit" className={css.button} icon={submitIcon} appearance="white-fill" disabled={disabled}>
                {submitTitle ?? t('createProfile')}
            </Button>
        </form>
    );
};

export default RegistrationForm;
