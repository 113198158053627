import { useState, useEffect } from 'react';
import useWindowSize from './useWindowSize';
import { getScreenType, ScreenType } from './index';

export default function useScreenType(): [ScreenType] {
    const { width } = useWindowSize();
    const [val, setVal] = useState<ScreenType>(getScreenType(width));

    useEffect(() => {
        setVal(getScreenType(width));
    }, [width]);

    return [val];
}
