import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'components/Page';
import AdaptiveIcons from 'components/AdaptiveIcons';
import Header from 'components/Header';
import Button from 'components/Button';
import css from './styles.module.scss';
import { ReactComponent as SignUpIcon } from 'assets/icons/signup.svg';

export interface Props {
    backClick?: () => void;
    continueClick: () => void;
}

const Success: React.FC<Props> = ({ backClick, continueClick }) => {
    const { t } = useTranslation();

    return (
        <Page type="success">
            <Header backClick={backClick} />

            <Page.Content className={css.content}>
                <Page.Icon>
                    <AdaptiveIcons name="mark" />
                </Page.Icon>

                <h3 className={css.title}>{t('profileCreatedSuccessfully')}</h3>

                <Button onClick={continueClick} className={css.moreButton} icon={<SignUpIcon />} appearance="white-fill">
                    {t('ok')}
                </Button>
            </Page.Content>
        </Page>
    );
};

export default Success;
