import React, { forwardRef } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';
import { refSetter } from 'libs/refSetter';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    inputElementClassName?: string;
}

const Input = forwardRef<HTMLInputElement, Props>(({ inputElementClassName, className, ...rest }, ref) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    return (
        <label className={cn(css.inputWrapper, className)}>
            <input className={cn(css.input, inputElementClassName)} {...rest} ref={refSetter(ref, inputRef)} />
        </label>
    );
});

export default Input;
