import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import Page from 'components/Page';
import AdaptiveIcons from 'components/AdaptiveIcons';
import AuthForm, { AuthFormData } from 'features/AuthForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getTransactions, login, selectUserData } from 'pages/Account/slice';
import * as routes from 'routes';
import css from './styles.module.scss';

const Login: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { push } = useHistory();
    const useData = useAppSelector(selectUserData);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = async (
        formData: AuthFormData,
    ): Promise<{ success: boolean; errors?: { [key in keyof AuthFormData]?: string } }> => {
        setLoading(true);
        dispatch(login(formData));

        try {
            const response = (await dispatch(getTransactions())) as { payload: { errorCode?: string } };
            if (response.payload?.errorCode) {
                return Promise.resolve({ success: false, errors: { email: response.payload?.errorCode } });
            } else {
                push(routes.lk());
                return Promise.resolve({ success: true });
            }
        } catch (_) {
            return Promise.resolve({ success: false, errors: { email: 'connection' } });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (useData) push(routes.lk());
    }, []);

    return (
        <Page type="success">
            <Header>
                <Link className={css.headerLink} to={routes.registration()}>
                    {t('signUp')}
                </Link>
            </Header>

            <Page.Content className={css.content}>
                <Page.Icon>
                    <AdaptiveIcons name="mark" />
                </Page.Icon>

                <AuthForm onSubmit={onSubmit} disabled={loading} />
            </Page.Content>
        </Page>
    );
};

export default Login;
