type RoutePath = string;

export const main = (): RoutePath => '/';
export const registration = (): RoutePath => '/registration';
export const login = main;
export const lk = (): RoutePath => '/lk';
export const settings = (): RoutePath => '/settings';
export const serverSettings = (): RoutePath => '/server-settings';
export const createProfile = (): RoutePath => '/create-profile';
export const identification = (): RoutePath => '/identification';
export const verification = (): RoutePath => '/verification';
