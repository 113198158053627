export function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
}

export function wait(delayInMS: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, delayInMS));
}

export const readBlobAsByteArray = (blob: Blob): Promise<Uint8Array> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const arrayBuffer = reader.result;

            resolve(new Uint8Array(arrayBuffer as ArrayBuffer));
        };

        reader.onerror = (e) => reject(e);
        reader.readAsArrayBuffer(blob);
    });
};

export type ScreenType = 'mobile' | 'desktop';

export const getScreenType = (width: number): ScreenType => {
    switch (true) {
        case width > 980:
            return 'desktop';
        default:
            return 'mobile';
    }
};

export function base64ToFile(base64_string: string, type: string) {
    const bytes = Uint8Array.from(atob(base64_string), (c) => c.charCodeAt(0));
    const blob = new Blob([bytes], { type: 'application/octet-stream' });
    return new File([blob], 'file', { type });
}

export const formatMoney = (money: number): string => {
    if (money !== null) return money.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    else return '0';
};

export const stringFormatMoney = (money: string): string => {
    if (!money) return '';

    const [first, second] = money.split('.');

    if (second) return `${formatMoney(parseInt(first, 10))},${second.length === 1 ? second + '0' : second}`;

    return formatMoney(parseInt(first, 10));
};
