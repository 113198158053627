import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputField from 'components/form/InputField';
import Button from 'components/Button';
import { ReactComponent as SignUpIcon } from 'assets/icons/signup.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

const schema = yup
    .object({
        email: yup.string().email().required(),
        password: yup.string().required(),
    })
    .required();

export type AuthFormData = AuthData;

export interface Props extends Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> {
    submitIcon?: React.ReactNode;
    disabled?: boolean;
    onSubmit: (data: AuthFormData) => Promise<{ success: boolean; errors?: { [key in keyof AuthFormData]?: string } }>;
}

const AuthForm: React.FC<Props> = ({ onSubmit, submitIcon = <SignUpIcon />, disabled, ...rest }) => {
    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<AuthFormData>({
        resolver: yupResolver(schema),
    });

    const submitAction = async (data: AuthFormData) => {
        const result = await onSubmit(data);

        if (!result.success) {
            Object.keys(result?.errors ?? {}).forEach((fieldName) =>
                setError(fieldName as keyof AuthFormData, {
                    type: result?.errors?.[fieldName as keyof AuthFormData],
                    message: 'error',
                }),
            );
        }
    };

    return (
        <form className={css.form} onSubmit={handleSubmit(submitAction)} {...rest}>
            <Controller
                name="email"
                defaultValue=""
                control={control}
                render={({ field }) => (
                    <InputField
                        {...field}
                        autoComplete="no"
                        type="text"
                        className={css.field}
                        placeholder={t('email')}
                        errors={errors.email ? [t(`errors.${errors.email.type}`)] : undefined}
                        disabled={disabled}
                    />
                )}
            />

            <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <InputField
                        {...field}
                        autoComplete="no"
                        className={css.field}
                        type="password"
                        errors={errors.password ? [t(`errors.${errors.password.type}`)] : undefined}
                        placeholder={t('password')}
                        disabled={disabled}
                    />
                )}
            />

            <Button type="submit" className={css.button} icon={submitIcon} appearance="white-fill" disabled={disabled}>
                {t('logIn')}
            </Button>
        </form>
    );
};

export default AuthForm;
